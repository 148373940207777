.home {
  position: relative;
  &::before {
    background-color: var(--bodybg);
    content: "";
    position: absolute;
    z-index: -9;
    top: 0;
    height: 100%;
    width: 100%;
  }

  h1 {
    color: var(--white);
    font-size: var(--heading48);
    font-weight: 800;
    margin-bottom: 28px;
    // white-space: nowrap;
  }
}
.ourPartner {
  background-image: url("../../assets/partnerbg.png");
  background-size: 100% 100% contain;
  background-repeat: no-repeat;
  padding: 100px 0;
  @media (max-width: 991px) {
    background-image: unset;
    padding: 40px 0;
  }
  @media (max-width: 575px) {
    background-image: unset;
    padding: 20px 0;
  }

  &_inner {
    .PartHead {
      text-align: center;
      background: var(--headinggradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: var(--heading60);
      line-height: 60px;
      font-weight: 600;
      padding-bottom: 50px;
      @media (max-width: 767px) {
        margin: unset;
        line-height: 40px;
        padding-bottom: 10px;
      }
    }
    &_logos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-flow: wrap;
      @media (max-width: 1199px) {
        justify-content: center;
      }
      img {
        @media (max-width: 575px) {
          max-width: 120px;
        }
      }
    }
  }
}
