@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // Color vars//
  --parrotColor: #cffe25;
  --darkBlue: #00bef2;
  --darkPurple: #0000b2;
  --HoverAnchor: #d4d4d4;
  --bg-181818: #181818;
  --inputborder: #383838;
  --roadmaphead: #00ffff;
  --textd1d1: #d1d1d1;
  --bodybg: #121212;
  --bg252525: #252525;
  --innercardbg: #1e1e1e;
  --text595959: #595959;
  --downloadbg: #c2c6cc;
  --textd1d1d1: #d1d1d1;
  --bgbenefitImg: #d9d9d9;
  --FooterBg: #0d1b26;
  --brandPurple10: #5700ff10;
  --dropborder: #cffe25;
  --bordercard: #e3e3e3;
  --coreGray: #9ba0a6;
  --offWhite: #f9f9fd;
  --black: #000000;
  --usecaseborder: rgba(255, 255, 255, 0.1);
  --mutedBlack: rgba(0, 0, 0, 0.5);
  --white: #ffffff;
  --validation: #ffb82d;
  --error: #f93232;
  --success: #00b25d;
  --disabledButton: #5500ff10;
  --blue: #4285f4;
  --lightPink: #f8f8fc;
  --black60: #00000099;
  --black40: #00000040;
  --grayBorder: #ffffff1a;
  --grayE9E9EF: #e9e9ef;
  --grayDark636364: #636364;
  --borderColor: rgba(175, 168, 168, 0.4);
  --gray666666: #666666;
  --gray656565: #656565;
  --commonPtext: #9ba0a6;
  --headingColor: #ececec;
  --dropmenu: rgb(49, 48, 48);
  --cardbg: linear-gradient(
    289.13deg,
    rgba(222, 243, 255, 0.2) 12.47%,
    rgba(255, 255, 255, 0) 124.3%
  );
  --cardborder: linear-gradient(
    257.6deg,
    rgba(255, 255, 255, 0.3) 35.29%,
    rgba(255, 255, 255, 0.06) 72.31%
  );
  --headinggradient: linear-gradient(333.73deg, #393939 35.4%, #eee 96.42%);
  --headinggradientTwo: linear-gradient(
    92.71deg,
    #ffffff 2.26%,
    #404040 81.47%
  );
  --FranchiseeBg: rgba(255, 255, 255, 0.2);
  --hrBgcolor: #ffffff21;
  --faqBorder: #222222;
  --black1a202e: #1a202e;
  --lightf1f5f9: #f1f5f9;
  --quickBenefitsBg: #0c0c0c;
  --triskelCards: #161616;

  // Typography //
  --heading100: 100px;
  --heading80: 80px;
  --heading70: 70px;
  --heading50: 50px;
  --heading64: 64px;
  --heading60: 60px;
  --heading56: 56px;
  --heading48: 48px;
  --heading44: 44px;
  --heading40: 40px;
  --headingOne: 32px;
  --heading30: 30px;
  --heading26: 26px;
  --heading28: 28px;
  --headingTwo: 22px;
  --headingThree: 24px;
  --headingFour: 20px;
  --largeParaGraph: 18px;
  --largeParaGraph16: 16px;
  --regularParagraph: 15px;
  --mediumParaGraph: 16px;
  --textfourteen: 14px;
  --smallParagraph: 13px;
}

/* Responsive styles */
@media screen and (max-width: 1440px) {
  :root {
    --heading64: 52px;
    --heading56: 46px;
    --heading48: 34px;
    --heading70: 50px;
    --heading80: 52px;
    --heading60: 48px;
    --heading44: 34px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --heading64: 43px;
    --heading56: 40px;
    --heading100: 70px;
    --heading64: 30px;
    --heading70: 30px;
    --heading80: 46px;
    --heading48: 28px;
    --heading50: 30px;
    --heading80: 44px;
    --heading44: 28px;
    --heading60: 42px;
    --heading40: 32px;
    --headingOne: 28px;
    --heading30: 26px;
    --heading26: 18px;
    --heading28: 18px;
    --headingTwo: 20px;
    --headingThree: 22px;
    --headingFour: 18px;
    --largeParaGraph: 16px;
    --largeParaGraph16: 15px;
    --regularParagraph: 14px;
    --textfourteen: 12px;
    --mediumParaGraph: 13px;
    --smallParagraph: 12px;
  }
}

@media screen and (max-width: 575px) {
  :root {
    --heading64: 32px;
    --heading56: 28px;
    --heading100: 40px;
    --heading48: 28px;
    --heading44: 22px;
    --heading80: 44px;
    --heading40: 20px;
    --heading50: 30px;
    --headingOne: 24px;
    --heading60: 30px;
    --heading30: 20px;
    --headingTwo: 18px;
    --heading26: 16px;
    --heading28: 16px;
    --headingThree: 20px;
    --headingFour: 16px;
    --largeParaGraph: 14px;
    --largeParaGraph16: 14px;
    --regularParagraph: 13px;
    --mediumParaGraph: 12px;
    --smallParagraph: 11px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  // font-family: "Mulish", sans-serif;
  margin: 0;
  padding: 0;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--bodybg);
}
* {
  // font-family: "Poppins", sans-serif ;
  box-sizing: border-box;
}
#root {
  width: 100%;
}

// img {
//   width: 100%;
// }

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

h2.ant-typography {
  font-size: var(--heading70);
  margin: unset;
  line-height: unset;
  text-align: center;
}

h3.ant-typography {
  font-size: var(--heading40);
  margin: unset;
  line-height: unset;
  color: var(--darkBlue);
}

.container {
  padding: 0 15px;
  max-width: 1597px;
  margin: auto;
}

.bannerBlueText {
  color: var(--HoverAnchor);
  white-space: nowrap;

  @media (max-width: 480px) {
    white-space: wrap;
  }
}

.form-outer {
  // max-width: 690px;
  background-color: rgba(6, 18, 32, 1);
  border-radius: 15px;
  padding: 60px 40px;
  border: 1px solid rgba(38, 41, 60, 0.7);
  margin: auto;

  @media (max-width: 991px) {
    padding: 40px 30px;
  }

  @media (max-width: 767px) {
    padding: 30px 20px;
  }

  textarea {
    background-color: #131e2b;
    border: 1px solid rgba(38, 41, 60, 0.7);
    color: white;
    z-index: 2;
    &.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
      background-color: #131e2b;
    }

    &.ant-input-outlined {
      &:focus,
      &:hover,
      &:focus-within {
        background-color: #131e2b;
        border-color: var(--darkBlue);
      }
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .ant-form-item {
    .ant-form-item-label > label {
      color: rgba(255, 255, 255, 0.5);
    }

    .ant-form-item-explain-error {
      text-align: initial;
      font-size: 12px;
    }
  }

  button {
    margin: auto;
  }
}
.customInput {
  input.ant-input {
    background-color: #131e2b;
    padding: 15px 18px;
    border: 1px solid rgba(38, 41, 60, 0.7);
    color: white;
    z-index: 2;
    @media (max-width: 991px) {
      padding: 12px 15px;
    }

    @media (max-width: 767px) {
      padding: 10px 12px;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    &:focus,
    &:hover,
    &:focus-within {
      background-color: #131e2b;
      border-color: var(--darkBlue);
    }
  }

  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background-color: #131e2b;
  }

  .ant-input-outlined {
    border: unset;
  }
}
.ant-select-dropdown {
  background-color: var(--innercardbg) !important;
  .ant-select-item-option {
    &:hover {
      background-color: var(--bodybg) !important;
    }
  }
  .ant-select-item-option-content {
    color: var(--white);
  }
}
.ant-dropdown-trigger {
  cursor: pointer;
  border: 1px solid var(--dropborder);
  color: var(--dropborder);
  padding: 10px 45px;
  border-radius: 26px;
  // max-width: 174px;
  width: 100%;
  display: flex;
  font-weight: 500;
  justify-content: center;
  gap: 12px;
  @media (max-width: 1366px) {
    // width: 139px;
  }
}
.ant-dropdown-menu {
  background-color: var(--dropmenu) !important;
  .ant-dropdown-menu-item {
    color: var(--dropborder) !important;
    &:hover {
      background-color: var(--dropborder) !important;
      color: var(--black) !important;
    }
  }
  .ant-dropdown-menu-item-active {
    .ant-dropdown-menu-title-content {
      a {
        svg {
          fill: black !important;
          stroke: black !important;
        }
      }
    }
  }
}

.greentext {
  color: var(--dropborder);
  font-size: var(--headingFour);
  font-weight: 400;
}
//playappstorebutton
.bannerbuttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 30px;
  @media (max-width: 575px) {
    gap: 10px;
  }
  a {
    max-height: 60px;
    @media (max-width: 575px) {
      max-height: unset;
    }
    svg {
      height: 100%;
      max-width: 200px;
      width: 100%;
      @media (max-width: 575px) {
        max-width: unset;
      }
      &:hover {
        -webkit-box-shadow: 0px 0px 19px 0px rgba(174, 199, 72, 1);
        -moz-box-shadow: 0px 0px 19px 0px rgba(174, 199, 72, 1);
        box-shadow: 0px 0px 19px 0px rgba(174, 199, 72, 1);
        border-radius: 30px;
        @media (max-width: 575px) {
          -webkit-box-shadow: unset;
          -moz-box-shadow: unset;
          box-shadow: unset;
        }
      }
    }
  }
}
// .commonHead {
//   font-weight: 600;
//   font-size: var(--heading26);
// }
// .commonText {
//   font-size: var(--largeParaGraph);
//   font-weight: 300;
//   color: var(--textd1d1);
// }
.ant-modal {
  .ant-modal-content {
    .modalImg {
      max-width: 458px;
      width: 100%;
    }
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-close {
    @media (max-width: 575px) {
      width: 10px;
    }
    &:hover {
      background-color: transparent;
    }
  }
}
.playApple {
  display: flex;
  gap: 10px;
  // svg{
  //   filter:invert(120%)
  // }
}
.d-flex {
  display: flex !important;
  align-items: center;
  gap: 10px;
}
.strong {
  font-weight: 600;
  // color: var(--textd1d1d1);
  color: var(--white);
}
.ant-tooltip {
  .ant-tooltip-inner {
    background-color: var(--white);
    color: var(--black);
  }
}
//******Franchisee Dropdown**********
.Franchisee {
  .ant-dropdown-menu {
    // max-width: 341px;
    width: 100%;
    background: var(--FranchiseeBg) !important;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 15px;
    padding: 15px;
    @media (max-width: 426px) {
      padding: 10px;
      width: 277px;
    }
    .ant-dropdown-menu-item {
      padding: 0 !important;
      &:hover {
        background: unset !important;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        cursor: pointer;
        &:hover {
          background-color: var(--hrBgcolor);
          border-radius: 10px;
        }
        span {
          font-size: var(--textfourteen);
          color: var(--white);
          font-weight: 400;
        }
      }
      p {
        font-size: var(--textfourteen);
        color: var(--white);
        font-weight: 400;
        padding: 10px 5px 0px 10px;
        line-height: 21px;
      }
      hr {
        color: var(--hrBgcolor);
        max-width: 264px;
        width: 100%;
        margin: 0;
        margin-top: 5px;
      }
      .ant-dropdown-menu-title-content {
        &:hover {
          svg {
            fill: var(--white) !important;
          }
        }
      }
    }
  }
}
.faqPage {
  background: var(--white);
  .headerItem_Main__headerlogo {
    .darkLogo {
      display: block;
    }
    .lightLogo {
      display: none;
    }
  }
  .headerItem_Main__rightSec a {
    color: var(--black);
  }
  .Franchisee .ant-dropdown-menu {
    background-color: var(--dropmenu) !important;
  }
  .navButtons {
    .ant-dropdown-trigger {
      color: var(--black);
      border-color: var(--black);
    }
  }
  .menuToggle {
    svg {
      path {
        fill: var(--black);
      }
    }
  }
  .ant-drawer-content {
    .headerItem_Main__rightSec {
      background-color: var(--white) !important;
    }
  }
  .ant-drawer-close {
    .anticon-close {
      svg {
        fill: var(--black) !important;
      }
    }
  }
}
.asteriskLeft {
  label {
    flex-direction: row-reverse;
    text-align: left;
    justify-content: flex-end;
    span {
      color: var(--parrotColor) !important;
    }
  }
}
.purposeSelect {
  label {
    span {
      color: var(--parrotColor) !important;
    }
  }
}

.ant-message {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.cryptoCard {
  display: flex;
  align-items: center;
  gap: 100px;
  padding-bottom: 124px;
  padding-top: 147px;
  @media (max-width: 1366px) {
    flex-flow: wrap;
  }
  @media (max-width: 767px) {
    justify-content: center;
    gap: 30px;
    padding-bottom: 40px;
    padding-top: 60px;
  }
  &_left {
    max-width: 581px;
    width: 100%;
    video {
      max-width: 581px;
      width: 100%;
      @media (max-width: 575px) {
        max-width: 300px;
        margin: auto;
        display: block;
      }
    }
  }
  &_right {
    h2 {
      font-size: var(--heading60);
      font-weight: 600;
      background: var(--headinggradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: 30px;
    }
    p,
    li {
      max-width: 783px;
      width: 100%;
    }
    ul {
      margin-top: 40px;
      list-style: none;
      padding: 0;
      li {
        margin: 28px 0;
        svg {
          margin-right: 10px;
        }
      }
    }
    .bannerbuttons {
      justify-content: flex-start;
      margin-top: 60px;
    }
  }
}
.tableRow {
  display: flex;
  justify-content: center;
  margin-top: 147px;
  @media (max-width: 767px) {
    margin-top: 47px;
  }
  svg {
    max-width: 1200px;
  }
}
.cryptoCardTable {
  color: #fff;
  font-size: var(--largeParaGraph16);
  font-weight: 400;
  margin: auto;
  background: #252525;
  display: none;
  th,
  td {
    padding: 20px;
    text-align: center;
  }
  th {
    font-weight: 800;
  }
  tbody {
    td {
      border-bottom: 1px solid #4c4c4c;
    }
    tr:nth-child(odd) {
      td:first-child {
        background: #1f1f1f;
      }
    }
    tr:last-child {
      td {
        border: none;
      }
    }
  }
  @media (max-width: 767px) {
    th,
    td {
      padding: 5px;
      font-size: 11px;
      line-height: 1.2;
    }
  }
}
