.footernew {
  background-color: var(--bodybg);
  padding: 20px 0px 5px;
  width: 100%;
  @media (max-width: 767px) {
    padding: 20px 0 5px;
  }
  .footerlogo {
    margin-bottom: 25px;
    display: block;
    img{
      width: 166px;
    }
    @media (max-width: 575px) {
      text-align: center;
      margin-bottom: 0;
    }
  }
  .ant-row {
    padding: 10px 0 10px 0;
    a {
      color: var(--textd1d1d1);
      font-size: var(--textfourteen);
      padding-bottom: 8px;
      line-height: 35px;
      font-weight: 300;
      // z-index: 99;
      @media (max-width: 575px) {
        padding-bottom: 8px;
      }
      display: block;
      &:hover {
        color: var(--dropborder);
        transition: 0.3s linear;
      }
    }
    h2 {
      color: var(--white);
      display: flex;
      font-weight: 500;
      gap: 7px;
      padding-bottom: 0px;
      font-size: 22px;
      align-items: center;
    }
    h3 {
      color: var(--white);
      display: flex;
      font-size: var(--largeParaGraph16);
      font-weight: 500;
      color: var(--dropborder);
      gap: 7px;
      padding-bottom: 30px;
    }
    p {
      font-size: var(--largeParaGraph16);
      font-weight: 300;
      max-width: 492px;
      word-break: break-word;
      margin-bottom: 0;
      line-height: 32px;
      color: var(--textd1d1d1);
      @media (max-width: 767px) {
        max-width: 100%;
      }
      @media (max-width: 575px) {
        text-align: center;
      }
    }
    .followus {
      @media (max-width: 575px) {
        text-align: center;
        display: block;
      }
    }
    @media (max-width: 767px) {
      display: flex;
      flex-flow: column-reverse;
    }
    @media (max-width: 575px) {
      align-items: center;
    }
    .footerLegal a {
      position: relative;
      transition: 0.3s linear;
      width: fit-content;
      &::after {
        content: "";
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 0;
        height: 1px;
        background: #b9e322;
        z-index: 9;
        transition: 0.3s linear;
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
  p {
    color: var(--white);
  }
  &_year {
    text-align: center;
    border-top: 1px solid rgba(156, 144, 144, 0.1);
    padding: 16px 0 0 0;
    font-size: 12px;
  }
}
.footerInput {
  padding-top: 30px;
  @media (max-width: 575px) {
    padding-top: 30px;
  }
  h4 {
    color: var(--dropborder);
    font-weight: 500;
    font-size: var(--largeParaGraph16);
    padding-bottom: 18px;
    @media (max-width: 575px) {
      text-align: center;
    }
  }
  .ant-input-affix-wrapper {
    max-width: 365px;
    background: var(--bg252525) !important;
    border-radius: 30px;
    overflow: hidden;
    border: 1px solid transparent;
    width: 100%;
    padding: 4px 6px;
    margin-top: 25px;
    @media (max-width: 991px) {
      margin-top: 0;
    }
    @media (max-width: 767px) {
      max-width: 100%;
    }
    @media (max-width: 575px) {
      height: 60px;
    }
  }
  .ant-input {
    background: var(--bg252525) !important;
    color: #969696 !important;
    padding-left: 25px !important;
    &::placeholder {
      color: #969696;
    }
  }
}
.greenArrow {
  background-color: var(--dropborder);
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s linear;
  cursor: pointer;
  @media (max-width: 575px) {
    width: 50px;
    height: 50px;
  }
  &:hover {
    transform: rotate(47deg);
  }
}
.footerYear {
  color: var(--textd1d1d1);
  border-top: 1px solid var(--bg252525);
  padding-top: 23px;
  margin-top: 45px;
  max-width: unset !important;
  font-size: var(--largeParaGraph16);
}
.socialIcons {
  display: flex;
  gap: 16px;
  transition: none !important;
  a:hover {
    color: var(--dropborder);
    transform: scale3d(1, 1.05, 1.05);
    transition: 0.3s linear;
  }
  .YoutubeIcon {
    svg {
      width: 30px;
      height: 30px;
    }
    &:hover {
      color: var(--white) !important;
    }
  }
}

.footerLegal {
  display: flex;
  gap: 75px;
  @media (max-width: 767px) {
    gap: 30px;
    margin: 25px 0;
  }
}
