.whyTriskel {
  display: flex;
  align-items: flex-end;
  gap: 100px;
  padding-bottom: 164px;
  padding-top: 147px;
  @media (max-width: 1366px) {
    flex-flow: wrap;
  }
  @media (max-width: 767px) {
    justify-content: center;
    gap: 30px;
    padding-bottom: 40px;
    padding-top: 60px;
  }
  &_left {
    max-width: 581px;
    width: 100%;
    img {
      max-width: 581px;
      width: 100%;
      @media (max-width: 575px) {
        max-width: 300px;
        display: none;
      }
    }
  }
  &_right {
    h2 {
      font-size: var(--heading60);
      font-weight: 600;
      background: var(--headinggradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: 24px;
    }
    p {
      max-width: 783px;
      width: 100%;
    }
    &_cards {
      display: flex;
      gap: 16px;
      padding-top: 50px;
      flex-wrap: wrap;
      .cardsData {
        max-width: 280px;
        min-height: 298px;
        width: 100%;
        transition: 0.3s linear;
        border: 2px solid var(--bodybg);
        background-color: var(--innercardbg);
        padding: 50px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        &:hover {
          transform: scale3d(1.05, 1.05, 1.05);
          background: transparent;
        }
        @media (max-width: 1599px) {
          max-width: 32%;
        }
        @media (max-width: 991px) {
          max-width: 48.5%;
          padding: 16px;
          min-height: unset;
        }
        @media (max-width: 575px) {
          max-width: 100%;
        }
        h4 {
          color: var(--dropborder);
          font-weight: 500;
          padding-bottom: 18px;
          font-size: var(--headingFour);
          @media (max-width: 575px) {
            padding-bottom: 10px;
          }
        }
        p {
          line-height: 30px;
          flex: 1;
          @media (max-width: 575px) {
            line-height: 25px;
          }
        }

        span {
          color: rgba(255, 255, 255, 0.2);
          text-align: right;
          display: block;
        }
      }
    }
  }
}
